import { Inject, Injectable } from '@angular/core';
import { Drivers } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { IPlatformSync, PlatformSyncToken } from 'src/app/common/contracts/sync/platform-sync';
import {
  AppointmentDB,
  ArticleDB,
  AttachmentDB,
  AttachmentTypeDB,
  AttributeTemplateDB,
  AuditDB,
  CalendarResourceDB,
  CareProposalDB,
  ConfigDB,
  ContractArticleGroupDB,
  DeviceDB,
  DirectOrderDB,
  DoctorDB,
  ErpOrderDB,
  ErpTaskDB,
  FaxDB,
  GroupDB,
  HospitalDB,
  InstitutionAttributeDB,
  InstitutionAuditDB,
  InstitutionContactDB,
  InstitutionNoteDB,
  InsuranceContractDB,
  IntegratedCareDB,
  IntegratedCareReturnDeliveryDB,
  LoggingDB,
  MaintenanceDB,
  MatchDB,
  MedicalOrderRequestDB,
  NursingHomeDB,
  NursingServiceDB,
  OrderDB,
  PatientAppUserChannelDB,
  PatientAppUserDB,
  PatientDB,
  PatientNotesDB,
  PayerDB,
  PayerInfoDB,
  PharmacyDB,
  PlannedOrderDB,
  PortalNotificationDB,
  PostalCodeDB,
  PrescriptionRequestDB,
  ProductGroupDB,
  QuotesDB,
  RegionDB,
  ReminderDB,
  ReportDB,
  ReturnDeliveryDB,
  RightsetDB,
  SavedInstanceStateDB,
  ServiceDB,
  ServiceOrderDB,
  ServiceTaskDB,
  SingleOrderDB,
  StandardCareProposalDB,
  SubunitDB,
  SyncTimestampDB,
  TemplateDB,
  UsersDB,
} from 'src/app/common/repository/databases';
import { AlbertaStorage } from 'src/app/common/storage/alberta-storage';
import { SqliteStorageFactory } from 'src/app/common/storage/sqlite-storage-factory';

import { AuthService } from './auth.service';
import { IGenericStorage } from './contracts/database/generic-storage';
import { EnvironmentService } from './environment/environment.service';

const DRIVERS = [Drivers.IndexedDB, Drivers.LocalStorage];

@Injectable({ providedIn: 'root' })
export class DatabaseFactory {
  private _dbName = 'itlabs.db';
  private _storages: Record<string, IGenericStorage>;

  private _isMobile: boolean;

  get isMobile(): boolean {
    return this._isMobile;
  }

  get isFirstStart(): boolean {
    return !window.localStorage.getItem('isFirstStart');
  }

  set isFirstStart(isFirstStart: boolean) {
    window.localStorage.setItem('isFirstStart', String(isFirstStart));
  }

  constructor(
    public translate: TranslateService,
    private _authenticationService: AuthService,
    private _environmentService: EnvironmentService,
    @Inject(PlatformSyncToken) private _platformSync: IPlatformSync,
    private _sqliteStorageFactory: SqliteStorageFactory
  ) {}

  async createStorages(isMobile: boolean): Promise<Record<string, IGenericStorage>> {
    this._isMobile = isMobile;

    await this._platformSync.ready;

    await this.createDbName();

    if (this._isMobile) {
      await this.createMobileStorages();
    } else {
      await this.createBrowserStorages();
    }

    //    await this.resetNotifications();

    return this._storages;
  }

  private async resetNotifications() {
    const value = window.localStorage.getItem('notifications');
    if (value && value === 'finished') {
      return;
    }

    const stateStorage: IGenericStorage = this._storages['statePersister.db'];
    await stateStorage.ready();
    await stateStorage.clear();
    const attachmentStorage: IGenericStorage = this._storages[AttachmentDB];
    await attachmentStorage.ready();
    await attachmentStorage.clear();
    const syncTimestampStorage: IGenericStorage = this._storages['syncTimestamp.db'];
    await syncTimestampStorage.ready();
    await syncTimestampStorage.removeItem(AttachmentDB.substring(0, AttachmentDB.length - 3));
    window.localStorage.setItem('notifications', 'finished');
  }

  private async createDbName() {
    return new Promise<void>(resolve => {
      this._authenticationService.authenticatedEventPublisher
        .pipe(takeWhile(authEventData => !authEventData.isAuthenticated))
        .subscribe({
          complete: async () => {
            if (!this._authenticationService.authentication || !this._authenticationService.authentication.account) {
              throw new Error('No logged in user instance found.');
            }
            await this._environmentService.ready;
            const account = this._authenticationService.authentication.account;
            const branch = this._environmentService.isProductionBranch() ? 'master' : this._environmentService.branch;
            this._dbName = `${account.firstName[0]}${account.lastName[0]}${account._id.slice(-4)}_${branch}`;

            await this._sqliteStorageFactory.configure(this._dbName);

            resolve();
          },
        });
    });
  }

  private isDeletable(deletable: boolean, browserDeletable: boolean = true) {
    return this._platformSync.isCordova ? deletable : browserDeletable;
  }

  private async createMobileStorages() {
    this._storages = {
      [MaintenanceDB]: await this._sqliteStorageFactory.create(MaintenanceDB),
      'logger.db': await this._sqliteStorageFactory.create('logger.db', false, false),
      [AttachmentDB]: await this._sqliteStorageFactory.create(AttachmentDB),
      [PatientDB]: await this._sqliteStorageFactory.create(PatientDB),
      [ArticleDB]: await this._sqliteStorageFactory.create(ArticleDB),
      [OrderDB]: await this._sqliteStorageFactory.create(OrderDB),
      [AuditDB]: await this._sqliteStorageFactory.create(AuditDB),
      [TemplateDB]: await this._sqliteStorageFactory.create(TemplateDB),
      [MatchDB]: await this._sqliteStorageFactory.create(MatchDB),
      [ReportDB]: await this._sqliteStorageFactory.create(ReportDB),
      [PatientNotesDB]: await this._sqliteStorageFactory.create(PatientNotesDB),
      [NursingHomeDB]: await this._sqliteStorageFactory.create(NursingHomeDB),
      [NursingServiceDB]: await this._sqliteStorageFactory.create(NursingServiceDB),
      [HospitalDB]: await this._sqliteStorageFactory.create(HospitalDB),
      [InstitutionContactDB]: await this._sqliteStorageFactory.create(InstitutionContactDB),
      [InstitutionNoteDB]: await this._sqliteStorageFactory.create(InstitutionNoteDB),
      [InstitutionAuditDB]: await this._sqliteStorageFactory.create(InstitutionAuditDB),
      [PostalCodeDB]: await this._sqliteStorageFactory.create(PostalCodeDB),
      [DoctorDB]: await this._sqliteStorageFactory.create(DoctorDB),
      [PayerDB]: await this._sqliteStorageFactory.create(PayerDB),
      [PayerInfoDB]: await this._sqliteStorageFactory.create(PayerInfoDB),
      [UsersDB]: await this._sqliteStorageFactory.create(UsersDB),
      [GroupDB]: await this._sqliteStorageFactory.create(GroupDB),
      [RightsetDB]: await this._sqliteStorageFactory.create(RightsetDB),
      [RegionDB]: await this._sqliteStorageFactory.create(RegionDB),
      [StandardCareProposalDB]: await this._sqliteStorageFactory.create(StandardCareProposalDB),
      [CareProposalDB]: await this._sqliteStorageFactory.create(CareProposalDB),
      [ProductGroupDB]: await this._sqliteStorageFactory.create(ProductGroupDB),
      [InsuranceContractDB]: await this._sqliteStorageFactory.create(InsuranceContractDB),
      [PharmacyDB]: await this._sqliteStorageFactory.create(PharmacyDB),
      [FaxDB]: await this._sqliteStorageFactory.create(FaxDB),
      [SyncTimestampDB]: await this._sqliteStorageFactory.create(SyncTimestampDB, false, false),
      [ErpOrderDB]: await this._sqliteStorageFactory.create(ErpOrderDB),
      [DeviceDB]: await this._sqliteStorageFactory.create(DeviceDB),
      [AttributeTemplateDB]: await this._sqliteStorageFactory.create(AttributeTemplateDB),
      [SubunitDB]: await this._sqliteStorageFactory.create(SubunitDB),
      [ReturnDeliveryDB]: await this._sqliteStorageFactory.create(ReturnDeliveryDB),
      [ConfigDB]: await this._sqliteStorageFactory.create(ConfigDB),
      [AppointmentDB]: await this._sqliteStorageFactory.create(AppointmentDB),
      [ErpTaskDB]: await this._sqliteStorageFactory.create(ErpTaskDB),
      [PatientAppUserDB]: await this._sqliteStorageFactory.create(PatientAppUserDB),
      [PatientAppUserChannelDB]: await this._sqliteStorageFactory.create(PatientAppUserChannelDB),
      [IntegratedCareDB]: await this._sqliteStorageFactory.create(IntegratedCareDB),
      [SingleOrderDB]: await this._sqliteStorageFactory.create(SingleOrderDB),
      [DirectOrderDB]: await this._sqliteStorageFactory.create(DirectOrderDB),
      [ContractArticleGroupDB]: await this._sqliteStorageFactory.create(ContractArticleGroupDB),
      [ReminderDB]: await this._sqliteStorageFactory.create(ReminderDB),
      [PlannedOrderDB]: await this._sqliteStorageFactory.create(PlannedOrderDB),
      [QuotesDB]: await this._sqliteStorageFactory.create(QuotesDB),
      [IntegratedCareReturnDeliveryDB]: await this._sqliteStorageFactory.create(IntegratedCareReturnDeliveryDB),
      [InstitutionAttributeDB]: await this._sqliteStorageFactory.create(InstitutionAttributeDB),
      [PrescriptionRequestDB]: await this._sqliteStorageFactory.create(PrescriptionRequestDB),
      [MedicalOrderRequestDB]: await this._sqliteStorageFactory.create(MedicalOrderRequestDB),
      [PortalNotificationDB]: await this._sqliteStorageFactory.create(PortalNotificationDB),
      [ServiceDB]: await this._sqliteStorageFactory.create(ServiceDB),
      [ServiceOrderDB]: await this._sqliteStorageFactory.create(ServiceOrderDB),
      [ServiceTaskDB]: await this._sqliteStorageFactory.create(ServiceTaskDB),
      [CalendarResourceDB]: await this._sqliteStorageFactory.create(CalendarResourceDB),
      [AttachmentTypeDB]: await this._sqliteStorageFactory.create(AttachmentTypeDB),
      ['commandQueue.db']: await this._sqliteStorageFactory.create('commandQueue.db', false, false),
      ['attachmentsToSave']: await this._sqliteStorageFactory.create('attachmentsToSave', false, false),
      ['attachmentsToUpdate']: await this._sqliteStorageFactory.create('attachmentsToUpdate', false, false),
      ['attachmentsToRemove']: await this._sqliteStorageFactory.create('attachmentsToRemove', false, false),
      ['attachmentChunksMeta']: await this._sqliteStorageFactory.create(
        'attachmentChunksMeta',
        this.isDeletable(true, false),
        false
      ),
      ['attachmentChunks']: await this._sqliteStorageFactory.create(
        'attachmentChunks',
        this.isDeletable(true, false),
        false
      ),
      ['statePersister.db']: await this._sqliteStorageFactory.create(
        'statePersister.db',
        this.isDeletable(true, false),
        false
      ),
      [SavedInstanceStateDB]: await this._sqliteStorageFactory.create(
        SavedInstanceStateDB,
        this.isDeletable(true, false),
        false
      ),
    };
  }

  async createBrowserStorages(): Promise<void> {
    this._storages = {
      [AttachmentDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AttachmentDB}`,
          storeName: AttachmentDB,
          driverOrder: DRIVERS,
        },
        false
      ),
      [PatientDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientDB}`,
          storeName: PatientDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ArticleDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ArticleDB}`,
          storeName: ArticleDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [OrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${OrderDB}`,
          storeName: OrderDB,
          driverOrder: DRIVERS,
        },
        false
      ),
      [AuditDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AuditDB}`,
          storeName: AuditDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [TemplateDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${TemplateDB}`,
          storeName: TemplateDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [MatchDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${MatchDB}`,
          storeName: MatchDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ReportDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ReportDB}`,
          storeName: ReportDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PatientNotesDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientNotesDB}`,
          storeName: PatientNotesDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [NursingHomeDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${NursingHomeDB}`,
          storeName: NursingHomeDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [NursingServiceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${NursingServiceDB}`,
          storeName: NursingServiceDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [HospitalDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${HospitalDB}`,
          storeName: HospitalDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [InstitutionContactDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InstitutionContactDB}`,
          storeName: InstitutionContactDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [InstitutionNoteDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InstitutionNoteDB}`,
          storeName: InstitutionNoteDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [InstitutionAuditDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InstitutionAuditDB}`,
          storeName: InstitutionAuditDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PostalCodeDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PostalCodeDB}`,
          storeName: PostalCodeDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [DoctorDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${DoctorDB}`,
          storeName: DoctorDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [SubunitDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${SubunitDB}`,
          storeName: SubunitDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PayerDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PayerDB}`,
          storeName: PayerDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PayerInfoDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PayerInfoDB}`,
          storeName: PayerInfoDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [UsersDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${UsersDB}`,
          storeName: UsersDB,
          driverOrder: DRIVERS,
        },
        this._platformSync.canBeSynced
      ),
      [GroupDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${GroupDB}`,
          storeName: GroupDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [RightsetDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${RightsetDB}`,
          storeName: RightsetDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [RegionDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${RegionDB}`,
          storeName: RegionDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [StandardCareProposalDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${StandardCareProposalDB}`,
          storeName: StandardCareProposalDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [CareProposalDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${CareProposalDB}`,
          storeName: CareProposalDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ProductGroupDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ProductGroupDB}`,
          storeName: ProductGroupDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [InsuranceContractDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InsuranceContractDB}`,
          storeName: InsuranceContractDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PharmacyDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PharmacyDB}`,
          storeName: PharmacyDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [FaxDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${FaxDB}`,
          storeName: FaxDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [SyncTimestampDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${SyncTimestampDB}`,
          storeName: SyncTimestampDB,
          driverOrder: DRIVERS,
        },
        false
      ),
      [ErpOrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ErpOrderDB}`,
          storeName: ErpOrderDB,
          driverOrder: DRIVERS,
        },
        true
      ),

      [DeviceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${DeviceDB}`,
          storeName: DeviceDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [AttributeTemplateDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AttributeTemplateDB}`,
          storeName: AttributeTemplateDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [MaintenanceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${MaintenanceDB}`,
          storeName: MaintenanceDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ReturnDeliveryDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ReturnDeliveryDB}`,
          storeName: ReturnDeliveryDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ConfigDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ConfigDB}`,
          storeName: ConfigDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [AppointmentDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AppointmentDB}`,
          storeName: AppointmentDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ErpTaskDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ErpTaskDB}`,
          storeName: ErpTaskDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PatientAppUserDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientAppUserDB}`,
          storeName: PatientAppUserDB,
          driverOrder: DRIVERS,
        },
        false
      ),
      [PatientAppUserChannelDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PatientAppUserChannelDB}`,
          storeName: PatientAppUserChannelDB,
          driverOrder: DRIVERS,
        },
        false
      ),
      [IntegratedCareDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${IntegratedCareDB}`,
          storeName: IntegratedCareDB,
          driverOrder: DRIVERS,
        },
        true
      ),

      [SingleOrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${SingleOrderDB}`,
          storeName: SingleOrderDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [DirectOrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${DirectOrderDB}`,
          storeName: DirectOrderDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ContractArticleGroupDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ContractArticleGroupDB}`,
          storeName: ContractArticleGroupDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ReminderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ReminderDB}`,
          storeName: ReminderDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PlannedOrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PlannedOrderDB}`,
          storeName: PlannedOrderDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [QuotesDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${QuotesDB}`,
          storeName: QuotesDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [IntegratedCareReturnDeliveryDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${IntegratedCareReturnDeliveryDB}`,
          storeName: IntegratedCareReturnDeliveryDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [InstitutionAttributeDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${InstitutionAttributeDB}`,
          storeName: InstitutionAttributeDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PrescriptionRequestDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PrescriptionRequestDB}`,
          storeName: PrescriptionRequestDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [MedicalOrderRequestDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${MedicalOrderRequestDB}`,
          storeName: MedicalOrderRequestDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [PortalNotificationDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${PortalNotificationDB}`,
          storeName: PortalNotificationDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ServiceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ServiceDB}`,
          storeName: ServiceDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ServiceOrderDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ServiceOrderDB}`,
          storeName: ServiceOrderDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [ServiceTaskDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${ServiceTaskDB}`,
          storeName: ServiceTaskDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [CalendarResourceDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${CalendarResourceDB}`,
          storeName: CalendarResourceDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      [AttachmentTypeDB]: new AlbertaStorage(
        {
          name: `${this._dbName}_${AttachmentTypeDB}`,
          storeName: AttachmentTypeDB,
          driverOrder: DRIVERS,
        },
        true
      ),
      ['statePersister.db']: new AlbertaStorage(
        {
          name: `${this._dbName}_statePersister.db`,
          storeName: 'statePersister.db',
          driverOrder: DRIVERS,
        },
        false,
        false
      ),
    };

    this._storages['logger.db'] = new AlbertaStorage(
      {
        name: `${this._dbName}_${LoggingDB}`,
        storeName: 'logger',
        driverOrder: DRIVERS,
      },
      true
    );

    this._storages['attachmentChunks'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentChunks`,
        storeName: 'attachmentChunks',
        driverOrder: DRIVERS,
      },
      this.isDeletable(true, false),
      false
    );

    this._storages['attachmentsToSave'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentsToSave`,
        storeName: 'attachmentsToSave',
        driverOrder: DRIVERS,
      },
      false,
      false
    );

    this._storages['attachmentsToUpdate'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentsToUpdate`,
        storeName: 'attachmentsToUpdate',
        driverOrder: DRIVERS,
      },
      false,
      false
    );

    this._storages['attachmentsToRemove'] = new AlbertaStorage(
      {
        name: `${this._dbName}_attachmentsToRemove`,
        storeName: 'attachmentsToRemove',
        driverOrder: DRIVERS,
      },
      false,
      false
    );

    this._storages['commandQueue.db'] = new AlbertaStorage(
      {
        name: `${this._dbName}_commandQueue.db`,
        storeName: 'commandQueue.db',
        driverOrder: DRIVERS,
      },
      false,
      false
    );

    await Promise.all(
      Object.entries(this._storages).map(s => {
        (s[1] as AlbertaStorage).create();
      })
    );
  }
}
