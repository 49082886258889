import { IAttachment, IPatient, Therapy } from '@alberta/konexi-shared';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Platform } from '@ionic/angular';
import { cloneDeep } from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AttachmentWorkItem } from 'src/app/business/attachment/attachment-work-item';
import { IDispatcher } from 'src/app/common/contracts/dispatch/dispatcher';
import { Dispatcher } from 'src/app/common/dispatch/dispatcher';
import { Logger } from 'src/app/common/logging/logger';
import { AttachmentDto } from 'src/app/shared/models/attachment/attachment-dto.model';
import { AttachmentMetadata } from 'src/app/shared/models/attachment/attachment-meta-data.model';
import { AttachmentDatabaseService } from 'src/app/shared/services/attachment/attachment-database.service';
import {
  base64ToBlob,
  getMimeTypeFromBase64String,
  stripDataPartFromBase64String,
} from 'src/app/shared/services/attachment/attachment-helper';
import { AttachmentQueuesProcessorService } from 'src/app/shared/services/attachment/attachment-queues-processor.service';
import {
  AttachmentTransferService,
  OfflineUploadResult,
  UploadResult,
} from 'src/app/shared/services/attachment/attachment-transfer.service';
import { Database } from 'src/app/shared/services/attachment/Database';
import { v4 } from 'uuid';

import { IFeathersAppProvider } from '@services/contracts/sync/feathers-app-provider';
import { FeathersService } from '@services/feathers.service';
import { AttachmentModelName } from '../../models/model-names';
import { Therapies } from '../../models/therapy';
import { AuthService } from '../auth.service';
import { BodyPartService } from '../body-part.service';
import { ConnectionStateService } from '../connection-state.service';
import { AttachmentTypeService } from './attachment-type.service';

export type UploadReadyMetadata = Omit<AttachmentMetadata & { uniqueId: string }, 'name'>;
export type CreateMetadata = Partial<AttachmentMetadata> & { name: string };
type CreateObjectUrlType = (object: any) => string;

export const CreateObjectUrl = new InjectionToken<CreateObjectUrlType>('createObjectUrl', {
  providedIn: 'root',
  factory: () => URL.createObjectURL,
});

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  static TAG = 'AttachmentService';
  public LOCAL_PREFIX = 'local|';
  public PAGINATION_DEFAULT = 5;

  private _websocketConnectedSubscription = new Subscription();
  private _isBusyWithOfflineUpload: boolean;

  private _feathersAttachmentService: any;

  public get offlineTxSuccess(): Observable<OfflineUploadResult> {
    return this._attachmentTransferService.offlineTxSuccess;
  }

  constructor(
    private _auth: AuthService,
    private _platform: Platform,
    private _connectionStateService: ConnectionStateService,
    private _attachmentDbProvider: AttachmentDatabaseService,
    private _attachmentTransferService: AttachmentTransferService,
    private _attachmentQueuesProcessor: AttachmentQueuesProcessorService,
    private _logger: Logger,
    private _attachmentWorkItem: AttachmentWorkItem,
    private _bodyPartService: BodyPartService,
    private _attachmentTypeService: AttachmentTypeService,
    @Inject(Dispatcher) private _dispatcher: IDispatcher<AttachmentDto>,
    @Inject(CreateObjectUrl) private _createObjectUrl: CreateObjectUrlType,
    @Inject(FeathersService) private _feathersAppProvider: IFeathersAppProvider
  ) {}

  public async init() {
    this._logger.info('[AttachmentService] Initializing service');
    this._platform.pause.subscribe(_ => {
      if (this._websocketConnectedSubscription && !this._websocketConnectedSubscription.closed) {
        this._websocketConnectedSubscription.unsubscribe();
      }
    });
    this._platform.resume.subscribe(_ => this.registerOnlineObservable());
    this.registerOnlineObservable();
    this.performDelete = this.performDelete.bind(this);
    this.uploadLocal = this.uploadLocal.bind(this);
    this._feathersAttachmentService = this._feathersAppProvider.app.service('attachment');
  }

  public async save(attachments: AttachmentDto[]) {
    await this._platform.ready();
    if (this._platform.is('hybrid') || !attachments || !attachments.length) {
      return;
    }

    await Promise.all(attachments.map(attachment => this._attachmentWorkItem.create(attachment)));

    this._dispatcher.sync(AttachmentModelName, {
      created: attachments,
      updated: [],
      deleted: [],
    });
  }

  public async get(id: string, returnValue: 'blobUrl' | 'blob' | 'base64' = 'base64'): Promise<string | Blob> {
    let blob = await this.getLocalBlob(id);
    if (!blob) {
      const metadata = await this.getAttachmentDto(id);
      blob = await this.download(id, metadata.contentType);
    }

    return this.transformBlob(blob, returnValue);
  }

  private async getLocalBlob(id: string) {
    const blob = (await this._attachmentDbProvider.get(Database.Blob, id)) as Blob;
    if (!blob && id.startsWith(this.LOCAL_PREFIX)) {
      throw Error(`blob is missing for ${id}`);
    }

    return blob;
  }

  private async transformBlob(data: string | Blob, returnValue: 'blobUrl' | 'blob' | 'base64'): Promise<string | Blob> {
    if (data) {
      switch (returnValue) {
        case 'blobUrl':
          return Promise.resolve(this._createObjectUrl(data));
        case 'blob':
          return Promise.resolve(data);
        case 'base64':
          return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(data as Blob);
            reader.onload = () => {
              resolve(reader.result as string);
            };
          });
      }
    }
  }

  public async download(id: string, mimeType: string): Promise<Blob> {
    await this._auth.init;
    const blob = await this._attachmentTransferService.fetchBlob(id, mimeType);
    return blob;
  }

  public async create(base64String: string, metadata: CreateMetadata): Promise<UploadResult> {
    this._logger.addBreadcrumb({
      message: `${AttachmentService.TAG} addAttachment`,
      type: 'debug',
      data: {
        base64size: base64String?.length,
        metadata: metadata,
      },
    });
    if (!base64String) {
      return Promise.reject('no image data');
    }
    let base64DataInitial: string = stripDataPartFromBase64String(base64String);
    if (base64DataInitial.indexOf('data:') !== -1) {
      // new plugin, data attribut twice in base 64 string, replace again
      base64DataInitial = stripDataPartFromBase64String(base64DataInitial);
    }
    const base64Data = base64DataInitial;

    const mime: string = getMimeTypeFromBase64String(base64String);
    const blob: Blob = base64ToBlob(base64Data, mime);
    const filename = `${metadata.name ? metadata.name : 'unnamed.jpg'}`;
    const uploadMetadata = this.getUploadReadyMetadata(metadata);
    this._logger.addBreadcrumb({
      message: `${AttachmentService.TAG} addAttachment writeBlobToStorage`,
      type: 'debug',
      data: {
        blobSize: blob?.size,
      },
    });
    await this._attachmentDbProvider.writeBlobToStorage(uploadMetadata.uniqueId, blob);
    this._logger.addBreadcrumb({
      message: `${AttachmentService.TAG} addAttachment writeAttachmentToStorage`,
      type: 'debug',
    });
    await this._attachmentDbProvider.writeAttachmentToStorage({
      _id: uploadMetadata.uniqueId,
      filename,
      contentType: mime,
      mime,
      uploadDate: new Date(new Date().toUTCString()),
      metadata: {
        ...uploadMetadata,
        createdAt: new Date(new Date().toUTCString()),
        createdBy: this._auth.authentication.account._id,
      },
    });
    this._logger.addBreadcrumb({
      message: `${AttachmentService.TAG} addAttachment upload`,
      type: 'debug',
    });
    return this._attachmentTransferService.upload(
      { blob, metadata: uploadMetadata, filename, mime },
      uploadMetadata.uniqueId,
      this._connectionStateService.isConnected
    );
  }

  private getUploadReadyMetadata(metadata: CreateMetadata): UploadReadyMetadata {
    const newMetadata = cloneDeep(metadata) as unknown as UploadReadyMetadata;
    const localId = `${this.LOCAL_PREFIX}${v4()}`;
    newMetadata.uniqueId = localId;
    delete newMetadata['name'];
    return newMetadata;
  }

  public async uploadLocal(localId: string): Promise<UploadResult | null> {
    this._logger.info(`upload local ${localId}`);
    const isAlreadyUploaded = localId.startsWith(this.LOCAL_PREFIX) === false;
    if (isAlreadyUploaded) {
      return null;
    }
    const [blob, attachment] = await Promise.all([
      this._attachmentDbProvider.get(Database.Blob, localId),
      this._attachmentDbProvider.get(Database.Attachment, localId),
    ]);

    if (!blob || !attachment) {
      if (blob) {
        this._logger.error(
          'local attachment found without blob - deleting it',
          new Error('local attachment found without blob - deleting it')
        );
        await this._attachmentDbProvider.remove(Database.Attachment, localId);
      }
      return null;
    }
    const { metadata } = attachment;
    this._logger.info(`upload local ${localId} - uploading`);
    return this._attachmentTransferService.upload(
      { blob, metadata, filename: attachment.filename, mime: attachment.mime },
      localId,
      this._connectionStateService.isConnected
    );
  }

  public async update(attachment: AttachmentDto): Promise<boolean> {
    await this._attachmentDbProvider.writeAttachmentToStorage({
      ...attachment,
      uploadDate: new Date(new Date().toUTCString()),
      metadata: { ...attachment.metadata, updatedBy: this._auth.authentication.account._id },
    });

    await this._attachmentDbProvider.set(Database.AttachmentToUpdate, attachment._id, attachment);
    if (this._connectionStateService.isConnected) {
      await this.runAttachmentQueuesProcessor();
    }
    return true;
  }

  public async softDelete(attachment: AttachmentDto): Promise<boolean> {
    attachment.metadata.archived = true;

    await this._attachmentDbProvider.writeAttachmentToStorage({
      ...attachment,
      uploadDate: new Date(new Date().toUTCString()),
      metadata: { ...attachment.metadata, updatedBy: this._auth.authentication.account._id },
    });

    await this._attachmentDbProvider.set(Database.AttachmentToUpdate, attachment._id, attachment);
    if (this._connectionStateService.isConnected) {
      await this.runAttachmentQueuesProcessor();
    }
    return true;
  }

  public async delete(id: string): Promise<void> {
    if (!this._connectionStateService.isConnected) {
      await this._attachmentDbProvider.set(Database.AttachmentToRemove, id, undefined);
      return;
    }
    try {
      await this.performDelete(id);
    } catch (error) {
      this._logger.error('delete image failed', error);
      await this._attachmentDbProvider.set(Database.AttachmentToRemove, id, undefined);
    }
  }

  private async performDelete(id: string): Promise<void> {
    this._logger.info(`[AttachmentService] Deleting attachment ${id}`);
    const isRemoteAttachment = id.startsWith(this.LOCAL_PREFIX) === false;
    if (isRemoteAttachment) {
      this._logger.info(`[AttachmentService] Deleting remote attachment ${id}`);
      await this._attachmentTransferService.delete(id);
    }
    await this._attachmentDbProvider.purge(id);
    this._logger.info(`[AttachmentService] Deleted attachment ${id}`);
  }

  private async performUpdate(attachment: AttachmentDto): Promise<void> {
    this._logger.info(`[AttachmentService] Updating attachment ${attachment._id}`);
    const uploadedAttachment = await this._attachmentTransferService.update(attachment._id, attachment);
    await this._attachmentDbProvider.writeAttachmentToStorage(uploadedAttachment);
    await this._attachmentDbProvider.remove(Database.AttachmentToUpdate, attachment._id);
    this._logger.info(`[AttachmentService] Updated attachment ${attachment._id}`);
  }

  public async getAttachmentDto(id: string): Promise<AttachmentDto> {
    if (this._platform.is('hybrid')) {
      const attachment = this._attachmentDbProvider.getAttachmentDto(id);
      if (!attachment) {
        throw Error(`attachment is undefined for ${id}`);
      }
      return attachment;
    }

    // fetch from server
    let result: any;
    try {
      // edge case: reloading page before getting attachment chunks - init function not yet called
      if (!this._feathersAttachmentService) {
        this._feathersAttachmentService = this._feathersAppProvider.app.service('attachment');
      }
      result = await this._feathersAttachmentService.find({
        query: {
          _id: { $in: [id] },
        },
      });
    } catch (error) {
      window.logger.error(`Failed to load attachment ${id} from server`, error);
    }
    if (result?.data?.length) {
      return result.data[0];
    } else {
      throw new Error(`Attachment result empty for ${id}`);
    }
  }

  public async isAttachmentBlobLocallyAvailable(id: string): Promise<boolean> {
    return this._attachmentDbProvider.isAttachmentBlobAvailable(id);
  }

  public async getLocalAttachments(): Promise<string[]> {
    return this._attachmentDbProvider.getLocalAttachments();
  }

  public getTherapyName(attachment: IAttachment): string {
    const therapyId = attachment?.metadata?.therapyId;
    const therapyTypeId = attachment?.metadata?.therapyTypeId;
    let therapyName = Therapies.getTherapyAndTypeName(therapyId, therapyTypeId);

    if (therapyId === Therapy.WV && attachment?.metadata?.woundLocation !== 'undefined') {
      const woundLocation = parseInt(attachment?.metadata?.woundLocation, 10);
      const woundLocationName = this._bodyPartService.getFullBodyPartName(woundLocation);
      therapyName += `, ${woundLocationName}`;
    }
    return therapyName;
  }

  public getDisplayFileName(patient: IPatient, attachment: IAttachment): Promise<string> {
    if (!attachment || !patient) {
      return Promise.resolve('');
    }

    return this.createFileName(patient, attachment, true);
  }

  public async createMetaData(patient: IPatient, data: any): Promise<any> {
    const { image, ...meta } = data;
    meta['patientId'] = patient._id;
    meta['regionId'] = patient.regionId;
    meta['archived'] = false;
    const fileExtension = image.substring(image.indexOf('/') + 1, image.indexOf(';base64'));
    if (!meta['name']) {
      const attachmentTypeName = await this._attachmentTypeService.getTranslation(
        meta['type']
      );
      const timestamp = new Date().toISOString().substr(0, 10);
      meta['name'] = `${attachmentTypeName}_${patient.firstName}_${patient.lastName}_${timestamp}.${fileExtension}`;
    }

    return meta;
  }

  private async createFileName(
    patient: IPatient,
    attachment: IAttachment,
    includeTherapy?: boolean,
    fileExtension?: string
  ) {
    const createdAt = new Date(attachment.metadata.createdAt).toISOString().substr(0, 10);

    if (!fileExtension) {
      fileExtension = attachment.filename.substring(attachment.filename.indexOf('.') + 1, attachment.filename.length);
    }
    const attachmentType = await this._attachmentTypeService.getTranslation(attachment?.metadata?.type);

    if (includeTherapy) {
      let therapyName = '';
      const therapyItem = Therapies.getTherapy(attachment?.metadata?.therapyId);
      if (therapyItem && therapyItem.id > 0) {
        therapyName += `_${therapyItem.displayName}`;

        const therapyType = Therapies.getTherapyType(
          attachment?.metadata?.therapyId,
          attachment?.metadata?.therapyTypeId
        );
        if (therapyType) {
          therapyName += `_${therapyType.displayName}`;
        }
      }
      return `${attachmentType}_${patient.firstName}_${patient.lastName}${therapyName}_${createdAt}.${fileExtension}`;
    } else {
      return `${attachmentType}_${patient.firstName}_${patient.lastName}_${createdAt}.${fileExtension}`;
    }
  }

  private registerOnlineObservable(): void {
    this._logger.info('[AttachmentService] Registering online observable');
    try {
      if (this._websocketConnectedSubscription != null && !this._websocketConnectedSubscription.closed) {
        this._websocketConnectedSubscription.unsubscribe();
      }
    } catch (e) {}
    this._websocketConnectedSubscription = combineLatest([
      this._connectionStateService.connectionState,
      this._auth.authenticatedEventPublisher,
    ])
      .pipe(
        map(([connectionStatus, authEvent]) => ({ isOnline: connectionStatus.isOnline, authEvent })),
        distinctUntilChanged(
          (x, y) => x.authEvent.isAuthenticated === y.authEvent.isAuthenticated && x.isOnline === y.isOnline
        ),
        filter(value => value.isOnline && value.authEvent.isAuthenticated && !this._isBusyWithOfflineUpload),
        debounceTime(2 * 1000), // avoid flickering online -> offline -> online,
        switchMap(async _ => {
          this._isBusyWithOfflineUpload = true;
          try {
            await this.runAttachmentQueuesProcessor();
          } catch (error) {
            this._logger.error('Error on websocketConnectedSubscription', error);
          } finally {
            this._isBusyWithOfflineUpload = false;
          }
        })
      )
      .subscribe(() => {});
  }

  public async runAttachmentQueuesProcessor() {
    const runId = Math.floor(Math.random() * (10000000 - 1 + 1)) + 1;
    this._logger.info(`[AttachmentService-${runId}] Attachment queues processor started`);
    await this._attachmentQueuesProcessor.processQueues(
      this.performDelete.bind(this),
      this.uploadLocal.bind(this),
      this.performUpdate.bind(this)
    );
    this._logger.info(`[AttachmentService-${runId}] Attachment queues processor done`);
  }
}
