import { Inject, Injectable } from '@angular/core';
import { Logger } from '@common/logging/logger';

import { AttachmentTypeDB } from '@common/repository/databases';
import { SQLiteQueryBuilder } from '@common/storage/sqlite-query-builder';
import { TranslateService } from '@ngx-translate/core';
import { IQueryService } from '@services/contracts/query/query-service';
import { QueryService } from '@services/query/query.service';
import { IgetSelectionItemsOptions } from 'src/app/business/selectionitem-factory';
import { SelectionItem } from '../../models/selectionItem';

@Injectable({ providedIn: 'root' })
export class AttachmentTypeService {
  private _values_to_keys = new Map<string, number>();
  private _keys_to_translation = new Map<number, { de: string }>();
  private _ready: Promise<void>;

  constructor(
    @Inject(QueryService) private _queryService: IQueryService,
    private _translateService: TranslateService,
    private _logger: Logger
  ) {
    this._ready = this.fetchTypes();
  }

  public async fetchTypes() {
    let dbEntries: { value: string; attachmentTypeKey: number; translation: { de: string } }[];
    try {
      dbEntries = await this._queryService.raw<any>(
        {
          sql: new SQLiteQueryBuilder().select('value').from(AttachmentTypeDB).asString(),
          query: {
            $select: ['attachmentTypeKey', 'value', 'translation'],
            archived: { $ne: true },
          },
        },
        AttachmentTypeDB
      );
    } catch (error) {
      this._logger.error(`Failed to fetch attachment types`, error);
    }
    for (const dbEntry of dbEntries) {
      this._values_to_keys.set(dbEntry.value, dbEntry.attachmentTypeKey);
      this._keys_to_translation.set(dbEntry.attachmentTypeKey, dbEntry.translation);
    }
  }

  public async getAttachmentTypeKey(value: string) {
    await this._ready;
    return this._values_to_keys.get(value);
  }

  public async getAttachmentTypeValue(key: number) {
    await this._ready;
    for (const type of this._values_to_keys.entries()) {
      if (type[1] === key) {
        return type[0];
      }
    }
  }

  public async getTranslation(key: number | string) {
    if (key === undefined || key === null) {
      return this._translateService.instant('components.attachment.type_unkown') as string;
    }
    await this._ready;

    if (typeof key === 'string') {
      key = Number.parseInt(key);
    }
    const language = this._translateService.currentLang;
    const dbEntry = this._keys_to_translation.get(key);
    if (dbEntry !== undefined) {
      return this._keys_to_translation.get(key)[language] as string;
    }

    this._logger.error(`[AttachmentTypeService] No translation found for attachment type key ${key}`, new Error());
    return this._translateService.instant('components.attachment.type_unkown') as string;
  }

  public async getSelectionItems(options?: IgetSelectionItemsOptions) {
    await this._ready;

    const language = this._translateService.currentLang;
    let selectionItems: SelectionItem[] = [];
    for (const type of this._keys_to_translation.entries()) {
      selectionItems.push(new SelectionItem(type[0], type[1][language]));
    }

    console.log(selectionItems);

    if (options?.sortAlphabetic) {
      selectionItems = selectionItems.sort((a, b) => {
        const valueA = a.value.toUpperCase();
        const valueB = b.value.toUpperCase();
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      });
    }

    return selectionItems;
  }
}
