import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReturnDeliveryWorkItem } from 'src/app/business/return-delivery/return-delivery-work-item';

import { AppointmentWorkItem } from './appointment/appointment-work-item';
import { ArticleWorkItem } from './article/article-work-item';
import { AttachmentWorkItem } from './attachment/attachment-work-item';
import { AttachmentTypeWorkItem } from './attachmentType/attachment-type-work-item';
import { CalendarResourceWorkItem } from './calendar-resource/calendar-resource-work-item';
import { CareProposalWorkItem } from './care-proposal/care-proposal-work-item';
import { ConfigWorkItem } from './config/config-work-item';
import { ContractArticleGroupWorkItem } from './contract-article-group/contract-article-group-work-item';
import { DeviceWorkItem } from './device/device-work-item';
import { DirectOrderWorkItem } from './direct-order/direct-order-work-item';
import { ErpTaskWorkItem } from './erpTask/erp-task-work-item';
import { FaxWorkItem } from './fax/fax-work-item';
import { AttributeTemplateWorkItem } from './general/attribute-template-work-item';
import { PostalCodeWorkItem } from './general/postal-code-work-item';
import { DoctorWorkItem } from './institution/doctor-work-item';
import { HospitalWorkItem } from './institution/hospital-work-item';
import { InstitutionAttributeWorkItem } from './institution/institution-attribute-work-item';
import { InstitutionAuditWorkItem } from './institution/institution-audit-work-item';
import { InstitutionContactWorkItem } from './institution/institution-contact-work-item';
import { InstitutionNoteWorkItem } from './institution/institution-note-work-item';
import { NursingHomeWorkItem } from './institution/nursing-home-work-item';
import { NursingServiceWorkItem } from './institution/nursing-service-work-item';
import { PayerInfoWorkItem } from './institution/payer-info-work-item';
import { PayerWorkItem } from './institution/payer-work-item';
import { PharmacyWorkItem } from './institution/pharmacy-work-item';
import { SubunitWorkItem } from './institution/subunit-work-item';
import { InsuranceContractWorkItem } from './insurance-contract/insurance-contract-work-item';
import { IntegratedCareReturnDeliveryWorkItem } from './integrated-care-return-delivery/integrated-care-return-delivery-work-item';
import { IntegratedCareWorkItem } from './integrated-care/integrated-care-work-item';
import { LoggingWorkItem } from './logging/logging-work-item';
import { MaintenanceWorkItem } from './maintenance/maintenance-work-item';
import { MedicalOrderRequestWorkItem } from './medical-order/medical-order-request-work-item';
import { ErpOrderWorkItem } from './order/erp-order-work-item';
import { OrderWorkItem } from './order/order-work-item';
import { PatientAppUserWorkItem } from './patient-app-user/patient-app-user-work-item';
import { PatientWorkItem } from './patient/patient-work-item';
import { PatientNoteWorkItem } from './patientNotes/patient-notes-work-item';
import { PlannedOrderWorkItem } from './plannedOrder/planned-order-work-item';
import { PortalNotificationWorkItem } from './portal-notification/portal-notification-work-item';
import { PrescriptionRequestWorkItem } from './prescription/prescription-request-work-item';
import { ProductGroupWorkItem } from './product-group/product-group-work-item';
import { QuoteWorkItem } from './quotes/quote-work-item';
import { ReminderWorkItem } from './reminder/reminder-work-item';
import { ServiceWorkItem } from './service-entity/service-work-item';
import { ServiceOrderWorkItem } from './service-order-entity/service-order-work-item';
import { ServiceTaskWorkItem } from './serviceTask/service-task-work-item';
import { SingleOrderWorkItem } from './single-order/single-order-work-item';
import { StandardCareProposalWorkItem } from './standard-careproposal/standard-careproposal-work-item';
import { AuditWorkItem } from './swodoc/audit-work-item';
import { MatchWorkItem } from './swodoc/match-work-item';
import { ReportWorkItem } from './swodoc/report-work-item';
import { TemplateWorkItem } from './swodoc/template-work-item';
import { SyncTimestampWorkItem } from './timestamp/sync-timestamp-work-item';
import { GroupWorkItem as DecoratedGroupWorkItem } from './user-management/group-work-item';
import { GroupWorkItem } from './user-management/group-work-item-decorator';
import { RegionWorkItem as DecoratedRegionWorkItem } from './user-management/region-work-item';
import { RegionWorkItem } from './user-management/region-workItem-decorator';
import { RightsetWorkItem } from './user-management/right-set-work-item';
import { UsersWorkItem as DecoratedUsersWorkItem } from './user/user-work-item';
import { UsersWorkItem } from './user/user-work-item-decorator';
import { WorkItem } from './work-item';

@NgModule({
  providers: [],
})
export class WorkItemModule {
  static forChild(): ModuleWithProviders<WorkItemModule> {
    return { ngModule: WorkItemModule };
  }

  static forRoot(): ModuleWithProviders<WorkItemModule> {
    return {
      ngModule: WorkItemModule,
      providers: [
        { provide: WorkItem, useClass: ArticleWorkItem, multi: true },
        { provide: WorkItem, useClass: PatientWorkItem, multi: true },
        { provide: WorkItem, useClass: OrderWorkItem, multi: true },
        { provide: WorkItem, useClass: SingleOrderWorkItem, multi: true },
        { provide: WorkItem, useClass: AuditWorkItem, multi: true },
        { provide: WorkItem, useClass: MatchWorkItem, multi: true },
        { provide: WorkItem, useClass: ReportWorkItem, multi: true },
        { provide: WorkItem, useClass: TemplateWorkItem, multi: true },
        { provide: WorkItem, useClass: NursingHomeWorkItem, multi: true },
        { provide: WorkItem, useClass: NursingServiceWorkItem, multi: true },
        { provide: WorkItem, useClass: HospitalWorkItem, multi: true },
        { provide: WorkItem, useClass: PostalCodeWorkItem, multi: true },
        { provide: WorkItem, useClass: DoctorWorkItem, multi: true },
        { provide: WorkItem, useClass: PayerWorkItem, multi: true },
        { provide: WorkItem, useClass: PayerInfoWorkItem, multi: true },
        { provide: WorkItem, useClass: UsersWorkItem, multi: true },
        { provide: WorkItem, useClass: GroupWorkItem, multi: true },
        { provide: WorkItem, useClass: RegionWorkItem, multi: true },
        { provide: WorkItem, useClass: RightsetWorkItem, multi: true },
        { provide: WorkItem, useClass: StandardCareProposalWorkItem, multi: true },
        { provide: WorkItem, useClass: CareProposalWorkItem, multi: true },
        { provide: WorkItem, useClass: ProductGroupWorkItem, multi: true },
        { provide: WorkItem, useClass: InsuranceContractWorkItem, multi: true },
        { provide: WorkItem, useClass: PharmacyWorkItem, multi: true },
        { provide: WorkItem, useClass: SubunitWorkItem, multi: true },
        { provide: WorkItem, useClass: SyncTimestampWorkItem, multi: true },
        { provide: WorkItem, useClass: AttachmentWorkItem, multi: true },
        { provide: WorkItem, useClass: AttachmentTypeWorkItem, multi: true },
        { provide: WorkItem, useClass: PatientNoteWorkItem, multi: true },
        { provide: WorkItem, useClass: FaxWorkItem, multi: true },
        { provide: WorkItem, useClass: ErpOrderWorkItem, multi: true },
        { provide: WorkItem, useClass: DeviceWorkItem, multi: true },
        { provide: WorkItem, useClass: LoggingWorkItem, multi: true },
        { provide: WorkItem, useClass: AttributeTemplateWorkItem, multi: true },
        { provide: WorkItem, useClass: MaintenanceWorkItem, multi: true },
        { provide: WorkItem, useClass: InstitutionContactWorkItem, multi: true },
        { provide: WorkItem, useClass: InstitutionNoteWorkItem, multi: true },
        { provide: WorkItem, useClass: InstitutionAuditWorkItem, multi: true },
        { provide: WorkItem, useClass: ReturnDeliveryWorkItem, multi: true },
        { provide: WorkItem, useClass: ConfigWorkItem, multi: true },
        { provide: DecoratedUsersWorkItem, useClass: DecoratedUsersWorkItem },
        { provide: DecoratedGroupWorkItem, useClass: DecoratedGroupWorkItem },
        { provide: DecoratedRegionWorkItem, useClass: DecoratedRegionWorkItem },
        { provide: WorkItem, useClass: AppointmentWorkItem, multi: true },
        { provide: WorkItem, useClass: ErpTaskWorkItem, multi: true },
        { provide: WorkItem, useClass: IntegratedCareWorkItem, multi: true },
        { provide: WorkItem, useClass: PatientAppUserWorkItem, multi: true },
        { provide: WorkItem, useClass: DirectOrderWorkItem, multi: true },
        { provide: WorkItem, useClass: ContractArticleGroupWorkItem, multi: true },
        { provide: WorkItem, useClass: ReminderWorkItem, multi: true },
        { provide: WorkItem, useClass: PlannedOrderWorkItem, multi: true },
        { provide: WorkItem, useClass: QuoteWorkItem, multi: true },
        { provide: WorkItem, useClass: IntegratedCareReturnDeliveryWorkItem, multi: true },
        { provide: WorkItem, useClass: InstitutionAttributeWorkItem, multi: true },
        { provide: WorkItem, useClass: PrescriptionRequestWorkItem, multi: true },
        { provide: WorkItem, useClass: PortalNotificationWorkItem, multi: true },
        { provide: WorkItem, useClass: ServiceWorkItem, multi: true },
        { provide: WorkItem, useClass: ServiceOrderWorkItem, multi: true },
        { provide: WorkItem, useClass: ServiceTaskWorkItem, multi: true },
        { provide: WorkItem, useClass: MedicalOrderRequestWorkItem, multi: true },
        { provide: WorkItem, useClass: CalendarResourceWorkItem, multi: true },
      ],
    };
  }
}
