import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppointmentCommandHandler } from './appointment/appointment-command-handler';
import { ArticleCommandHandler } from './article/article-command-handler';
import { AttachmentTypeCommandHandler } from './attachment-type/attachment-type-command-handler';
import { CalendarResourceCommandHandler } from './calendarResource/calendar-resource-command-handler';
import { CareProposalCommandHandler } from './careproposal/careproposal-command-handler';
import { StandardCareProposalCommandHandler } from './careproposal/standard-careProposal-command-handler';
import { ConfigCommandHandler } from './config/config-command-handler';
import { DirectOrderCommandHandler } from './direct-order/direct-order-command-handler';
import { DoctorCommandHandler } from './doctor/doctor-command-handler';
import { EntityCommandHandler } from './entity-command-handler';
import { ErpTaskCommandHandler } from './erpTask/erp-task-command-handler';
import { FaxCommandHandler } from './fax/fax-command-handler';
import { GroupsCommandHandler } from './group/group-command-handler';
import { HospitalCommandHandler } from './hospital/hospital-command-handler';
import { InstitutionAttributeCommandHandler } from './institutionAttribute/institution-attribute-command-handler';
import { InstitutionAuditCommandHandler } from './institutionAudit/institution-audit-command-handler';
import { InstitutionContactCommandHandler } from './institutionContact/institution-contact-command-handler';
import { InstitutionNoteCommandHandler } from './institutionNote/institution-note-command-handler';
import { InsuranceContractCommandHandler } from './insuranceContract/insurance-contract-command-handler';
import { IntegratedCareReturnDeliveryCommandHandler } from './integrated-care-return-delivery/integrated-care-return-delivery-command-handler';
import { IntegratedCareCommandHandler } from './integratedCare/integrated-care-command-handler';
import { MaintenanceCommandHandler } from './maintenance/maintenance-command-handler';
import { MedicalOrderRequestCommandHandler } from './medical-order/medical-order-request-command-handler';
import { NursingHomeCommandHandler } from './nursing-home/nursing-home-command-handler';
import { NursingServiceCommandHandler } from './nursing-service/nursing-service-command-handler';
import { OrderCommandHandler } from './order/order-command-handler';
import { PatientAppUserCommandHandler } from './patient-app-user/patient-app-user-command-handler';
import { PatientCommandHandler } from './patient/patient-command-handler';
import { PatientNotesCommandHandler } from './patientNotes/patientNotes-command-handler';
import { PharmacyCommandHandler } from './pharmacy/pharmacy-command-handler';
import { PlannedOrderCommandHandler } from './planned-order/planned-order-command-handler';
import { PortalNotificationCommandHandler } from './portal-notification/portal-notification-command-handler';
import { PrescriptionRequestCommandHandler } from './prescription/prescription-request-command-handler';
import { ProductGroupCommandHandler } from './product/product-group-command-handler';
import { RegionsCommandHandler } from './region/region-command-handler';
import { ReminderCommandHandler } from './reminder/reminder-command-handler';
import { ReturnDeliveryCommandHandler } from './returnDelivery/return-delivery-command-handler';
import { ServiceCommandHandler } from './service-entity/service-command-handler';
import { ServiceOrderCommandHandler } from './service-order-entity/service-order-command-handler';
import { ServiceTaskCommandHandler } from './serviceTask/service-task-command-handler';
import { SingleOrderCommandHandler } from './single-order/single-order-command-handler';
import { SubunitCommandHandler } from './subunit/subunit-command-handler';
import { AuditCommandHandler } from './swodoc/audit-command-handler';
import { MatchCommandHandler } from './swodoc/match-command-handler';
import { ReportCommandHandler } from './swodoc/report-command-handler';
import { TemplateCommandHandler } from './swodoc/template-command-handler';
import { UsersCommandHandler } from './user/users-command-handler';

@NgModule({ providers: [] })
export class CommandModule {
  static forRoot(): ModuleWithProviders<CommandModule> {
    return {
      ngModule: CommandModule,
      providers: [
        { provide: EntityCommandHandler, useClass: ArticleCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: RegionsCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: UsersCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: PatientCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: OrderCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: SingleOrderCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: AuditCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: MatchCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ReportCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ProductGroupCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: TemplateCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: GroupsCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: StandardCareProposalCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: InsuranceContractCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: IntegratedCareCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: CareProposalCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: PatientNotesCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: FaxCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: NursingHomeCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: DoctorCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: HospitalCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: InstitutionNoteCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: InstitutionAuditCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: InstitutionContactCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: PharmacyCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: NursingServiceCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: MaintenanceCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: SubunitCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ReturnDeliveryCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ConfigCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: AppointmentCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ErpTaskCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: PatientAppUserCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: DirectOrderCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ReminderCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: PlannedOrderCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: IntegratedCareReturnDeliveryCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: InstitutionAttributeCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: PrescriptionRequestCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: MedicalOrderRequestCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: PortalNotificationCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ServiceCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ServiceOrderCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: ServiceTaskCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: CalendarResourceCommandHandler, multi: true },
        { provide: EntityCommandHandler, useClass: AttachmentTypeCommandHandler, multi: true },
      ],
    };
  }

  static forChild(): ModuleWithProviders<CommandModule> {
    return { ngModule: CommandModule };
  }
}
