import {
  ErpStatus,
  Gender,
  IAddress,
  ICareGiver,
  IPatient,
  IPatientDoctorRelation,
  IPatientHospitalRelation,
  IPatientNursingHomeRelation,
  IPatientNursingServiceRelation,
  IPatientPayerRelation,
  IPatientPharmacyRelation,
  PatientDeactivationReason,
  Title,
} from '@alberta/konexi-shared';
import { IDeliveryInformation } from '@alberta/konexi-shared/dist/patient/deliveryInformation';
import { ISearchable } from 'src/app/common/contracts/components/searchable';

import { IPatientReferrer } from '@alberta/konexi-shared/dist/patient/patientReferrer';
import { AggregateRoot } from '../aggregate-root';

export class PatientDto extends AggregateRoot implements IPatient, ISearchable {
  city: string;
  postalCode: string;
  customerContactId: string;
  deliveryAddress: IAddress;
  accountingStatus: boolean;
  // master data
  title: Title;
  gender: Gender;
  firstName: string;
  lastName: string;
  customerId: string;
  birthday: Date;
  calorieDemand: number;
  // contact data
  address: string;
  postalCodeId: string;
  phone: string;
  mobilePhone: string;
  fax: string;
  email: string;
  // relations
  payer: IPatientPayerRelation;
  hospital: IPatientHospitalRelation;
  nursingHome: IPatientNursingHomeRelation;
  nursingService: IPatientNursingServiceRelation;
  pharmacy: IPatientPharmacyRelation;
  primaryDoctorId: string;
  primaryDoctor: IPatientDoctorRelation;
  nursingHomeId: string;
  nursingServiceId: string;
  careGivers: ICareGiver[];
  // internal data
  regionId: string;
  fieldNurseId: string;
  nextVisit: Date;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  ivTherapy: boolean;
  classification: string;
  changeInSupplier: boolean;
  comment: string;
  pharmacyId: string;
  billingAddress: IAddress;
  additionalAddress: string;
  additionalAddress2: string;

  deactivationReason: PatientDeactivationReason;
  deactivationDate: Date;
  deactivationComment: string;
  erpStatus: ErpStatus;
  resmedTenant: string;
  imported: boolean;
  validToDate: Date;
  calendarResourceId: string;
  ignoreChangeForResmed: boolean;
  additionalUserId: string;
  additionalUserIds: string[];
  careDegree: 1 | 2 | 3 | 4 | 5;
  deliveryInformation: IDeliveryInformation;
  dashboardInfo: string[];
  status: string;
  additionalAttributes?: Record<string, any>;
  referrer: IPatientReferrer;

  constructor() {
    super();
    this.gender = Gender.Unknown;
    this.firstName = this.lastName = this.fieldNurseId = this.additionalUserId = '';
    this.careGivers = [];
  }

  get displayText(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
